@import 'font.scss';

$primary : #fb6340;
$primary-color : #fff;
html, body {
    -webkit-overflow-scrolling : touch !important;
    overflow: auto !important;
}
html {
    scroll-behavior: smooth;
    color: #2d2d2d !important;
}
.cursor-pointer {
    cursor: pointer;
}
.primary{
    background: $primary !important;
    color: $primary-color !important;
}
.background{
    background-color: #fefff7 !important;
    
}
.primary-text {
    color: $primary !important;
}
.main-text{
    color: #2d2d2d !important;
}
.pd-20 {
    padding: 20px;
}
.bg-white {
    background: white;
}
.m-b-40 {
  margin-bottom: 40px;
}
.text-center {
    text-align: center !important;
}
p {
    line-height: 1.3;
}
  


// ideal
#service {
    .card{
        border-radius: 50%;
        width: 100px;
    }
    @media only screen and (max-width: 600px) {
        .card{
            width: 50px;
        }
    }
}

#testimonial {
    .react-multi-carousel-dot button{
        border-color: $primary;
        background-color: white;
    }
    .react-multi-carousel-dot--active button{
        background: $primary;
    }
    .react-multi-carousel-list{
        position: static;
    }
    .react-multi-carousel-dot-list{
    }
}
.custom-textfield input {
    padding-top: 30px; 
    padding-bottom: 10px
}
.custom-textfield .MuiOutlinedInput-root {
    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(0, 0, 0, 0.23); /* Default disabled border style */
    }
}

/* Remove border when not disabled */
.custom-textfield .MuiOutlinedInput-root:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline {
    border: none !important; /* Remove border */
}