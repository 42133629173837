@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../font/Roboto/Roboto-Thin.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../font/Roboto/Roboto-Light.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../font/Roboto/Roboto-Regular.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../font/Roboto/Roboto-Medium.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../font/Roboto/Roboto-Bold.ttf');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../font/Roboto/Roboto-Black.ttf');
}